
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ActionButton extends Vue {
  @Prop() public action!: (item: any | undefined) => any
  @Prop() public tooltip!: string
  @Prop({ default: 'primary' }) public color!: string
  @Prop({ default: false }) public light!: boolean
  @Prop({ default: true }) public dark!: boolean
  @Prop({ default: false }) public outlined!: boolean
}
