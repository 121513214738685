
import { Component, Prop, Vue } from 'vue-property-decorator'
import DialogContent from '@/components/dialog/DialogContent.vue'
import SelectField from '@/components/form/SelectField.vue'
import DesignerService from '@/api/services/DesignerService'
import { ISnackbar } from '@/api/interfaces/SnackbarInterface'
import { namespace } from 'vuex-class'
import OverviewItem from '@/components/OverviewItem.vue'
import Designer from '@/api/models/Designer'

const Global = namespace('Global')

@Component({
  components: { OverviewItem, SelectField, DialogContent },
})
export default class DesignerOverviewDialog extends Vue {
  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Prop() private designerUuid!: string
  @Prop() private onSuccess!: () => void
  @Prop() private updatedCallback!: () => void

  private designer!: Designer
  private loaded: boolean = false

  private async created(): Promise<void> {
    this.designer = await DesignerService.show(this.designerUuid)
    this.loaded = true
  }

  private closeModal(): void {
    this.$root.$emit('closeModal')
  }
}
