
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import { ISnackbar } from '@/api/interfaces/SnackbarInterface'
import { AuthActions } from '@/store/actions/auth.actions'
import User from '@/api/models/User'

const Global = namespace('Global')

const Auth = namespace('Auth')

@Component({
  components: { LocaleSwitcher },
})
export default class LayoutBar extends Vue {
  private menu = false

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Global.Getter
  private isLoading!: boolean

  @Auth.Getter
  private getUser!: User

  logout(): void {
    this.$store.dispatch(AuthActions.LOG_OUT)
  }
}
