
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import IModalDialog from '@/api/interfaces/ModalDialogInterface'

const Global = namespace('Global')

@Component
export default class ModalDialog extends Vue {
  private dialog: boolean = false

  @Global.Getter
  private modalDialog!: IModalDialog

  @Watch('modalDialog') private onValueChange(): void {
    this.dialog = true
  }

  created(): void {
    this.$root.$on('closeModal', this.close)
  }

  close(): void {
    this.dialog = false
  }

  beforeDestroy(): void {
    this.$root.$off('closeModal', this.close)
  }
}
