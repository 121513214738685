import { AxiosError } from 'axios'

export default function parsedErrorResponseData(error: AxiosError): any {
  return (
    // @ts-ignore
    { ...(error.response && error.response.data), status: error.response?.status } || error.message || error.toString()
  )
}

export function parseErrorArray(errorList: any): string[] {
  const list: string[] = []

  Object.keys(errorList).forEach((key: string) => list.push(...errorList[key]))

  return list
}

export function getParsedValidationData(errors: any): IErrors[] {
  const allErrors: IErrors[] = []

  const errorFields = Object.keys(errors)
  errorFields.forEach((field: string) => {
    const errorString = errors[field].join(', ')

    allErrors.push({
      field,
      message: errorString,
    })
  })

  return allErrors
}

export function getFieldError(field: string, errors: IErrors[]): string {
  const error = errors.find((error) => error.field === field)

  return error?.message || ''
}

export interface IErrors {
  [key: string]: string
  message: string
}
