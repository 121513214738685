
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DataOptions, DataPagination } from 'vuetify'
import { debounce, isEmpty, get } from 'lodash'
import Sortable, { SortableEvent } from 'sortablejs'
import { AxiosResponse } from 'axios'
import {
  ICollectionResponse,
  IDataTableHeader,
  IDataTableQueryParameters,
  IDataTableReorderEvent,
  IDataTableResponse,
} from '@/api/interfaces/DatatableInterface'

@Component
export default class DataTable extends Vue {
  @Prop({ default: () => [] }) headers!: IDataTableHeader[]
  @Prop({ default: () => ({}) }) filters!: object
  @Prop({ default: 25 }) itemsPerPage!: number
  @Prop({ default: false }) hasBulkActions!: boolean
  @Prop({ default: () => [] }) sortBy!: string[]
  @Prop({ default: () => [] }) readonly sortDesc!: boolean[]
  @Prop({ required: true }) readonly itemKey!: string
  @Prop({ default: false }) readonly isOrderingEnabled!: boolean
  @Prop({ default: false }) readonly disableAutoPageReset: boolean = false

  @Prop({ required: true }) readonly fetchCallback!: (
    parameters: object,
  ) => Promise<IDataTableResponse<ICollectionResponse<object>>>

  @Prop() readonly reorderCallback!: (payload: IDataTableReorderEvent) => Promise<AxiosResponse>

  private isLoading: boolean = true
  private items: Array<object> = []
  private selectedItems: Array<object> = []
  private tableOptions: DataOptions | any = {
    page: 1,
    sortBy: [],
    sortDesc: [],
  }
  private searchText: string | null = null
  private paginationData: DataPagination = {
    page: 1,
    pageStart: 1,
    pageCount: 1,
    pageStop: 1,
    itemsPerPage: this.itemsPerPage,
    itemsLength: 1,
  }
  private debouncedSearch!: () => void

  get defaultHeaders(): IDataTableHeader[] {
    const slotHeaders = Object.keys(this.$scopedSlots)

    return this.headers.filter((header: IDataTableHeader) => {
      return !slotHeaders.includes(`item.${header.value}`)
    })
  }

  get hasItemsSelected(): boolean {
    return this.selectedItems.length > 0
  }

  get allQueryParameters(): object {
    let parameters = { ...this.staticQueryParameters }

    if (!isEmpty(this.filters)) {
      parameters = { ...parameters, ...this.filters }
    }

    return parameters
  }

  get staticQueryParameters(): IDataTableQueryParameters {
    const per_page = this.isOrderingEnabled ? 1000 : this.tableOptions.itemsPerPage || this.itemsPerPage

    const sort_by = this.isOrderingEnabled ? null : this.tableOptions.sortBy[0]

    let order: null | string = null

    if (!this.isOrderingEnabled) {
      order = this.tableOptions.sortDesc[0] ? 'desc' : 'asc'
    }

    const q = this.isOrderingEnabled ? null : this.searchText

    return {
      page: this.tableOptions.page,
      per_page,
      sort_by,
      order,
      q,
    }
  }

  private resolveItemValue(item: any, key: string): any {
    const value = get(item, key)

    if (value === null || value === '' || typeof value === 'undefined') {
      return '-'
    }

    return value
  }

  private onDragReorder(event: SortableEvent): void {
    if (typeof this.reorderCallback === 'undefined') {
      return
    }

    const itemId = String(
      event.item.className.match(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g),
    )

    if (typeof event.newIndex !== 'number') {
      return
    }

    this.reorderCallback({
      itemId,
      newIndex: event.newIndex,
    })
  }

  @Watch('searchText') search(): void {
    if (!this.disableAutoPageReset) {
      this.resetPage()
    }

    this.debouncedSearch()
  }

  @Watch('filters', { deep: true }) filtersChange(): void {
    if (!this.disableAutoPageReset) {
      this.resetPage()
    }
  }

  mounted(): void {
    if (typeof this.reorderCallback !== 'undefined') {
      const datatable = this.$refs.dataTable as any
      // eslint-disable-next-line no-new
      new Sortable(datatable.$el.getElementsByTagName('tbody')[0], {
        draggable: '.sortable-row',
        handle: '.sort-handle',
        onEnd: this.onDragReorder,
        animation: 150,
      })
    }
  }

  public resetPage(): void {
    this.tableOptions.page = 1
  }

  public fetch(): void {
    this.isLoading = true
    this.items = []

    this.fetchCallback(this.allQueryParameters)
      .then((response: IDataTableResponse<ICollectionResponse<any>>) => {
        const { current_page, from, last_page, per_page, to, total } = response.data.meta

        this.items = response.data.data
        this.paginationData = {
          page: current_page,
          pageStop: to || 0,
          pageStart: from ? from - 1 : 0,
          pageCount: last_page,
          itemsPerPage: per_page,
          itemsLength: total,
        }
      })
      .catch((): void => {
        this.items = []
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  @Watch('isOrderingEnabled')
  private onOrderingValueChange(newValue: boolean): void {
    if (newValue) {
      this.headers.unshift({
        text: '',
        value: 'sortHandle',
        sortable: false,
      })

      this.fetch()

      return
    }

    this.fetch()
    this.headers.shift()
  }

  private created(): void {
    this.$root.$on('dataTableRefresh', this.fetch)
    this.debouncedSearch = debounce(this.fetch, 300)
  }

  beforeDestroy(): void {
    this.$root.$off('dataTableRefresh', this.fetch)
  }

  private itemsSelected(): void {
    this.$nextTick(() => {
      this.$emit('items-selected', this.selectedItems)
    })
  }
}
