import i18n from '@/plugins/i18n'

export const projectStatuses = [
  {
    value: 'project.awaiting-payment',
    label: i18n.t('Common.AwaitingPayment'),
  },
  {
    value: 'project.canceled',
    label: i18n.t('Common.Canceled'),
  },
  {
    value: 'project.completed',
    label: i18n.t('Common.Completed'),
  },
  {
    value: 'project.disabled',
    label: i18n.t('Common.Disabled'),
  },
  {
    value: 'project.draft',
    label: i18n.t('Common.Draft'),
  },
  {
    value: 'project.in-progress',
    label: i18n.t('Common.InProgress'),
  },
  {
    value: 'project.ready',
    label: i18n.t('Common.Ready'),
  },
  {
    value: 'project.suspended',
    label: i18n.t('Common.Suspended'),
  },
  {
    value: 'project.WaitingToSignCta',
    label: i18n.t('Common.Sent'),
  },
]

export enum ProjectTypes {
  branding = 'branding',
  logo = 'logo',
  tShirt = 't-shirt',
  menu = 'menu',
  businessCard = 'business-card',
  packaging = 'packaging',
  website = 'website',
  calendar = 'calendar',
  banner = 'banner',
  facebookCover = 'facebook-cover',
  bookCover = 'book-cover',
  flier = 'flier',
  carSticker = 'car-sticker',
  productLabel = 'product-label',
  brochure = 'brochure',
  illustration = 'illustration',
  other = 'other',
}

export const projectTypes: any = {
  [ProjectTypes.branding]: {
    title: i18n.t('Common.Branding'),
  },
  [ProjectTypes.logo]: {
    title: i18n.t('Common.Logo'),
  },
  [ProjectTypes.tShirt]: {
    title: i18n.t('Common.T-Shirt'),
  },
  [ProjectTypes.menu]: {
    title: i18n.t('Common.Menu'),
  },
  [ProjectTypes.businessCard]: {
    title: i18n.t('Common.BusinessCard'),
  },
  [ProjectTypes.packaging]: {
    title: i18n.t('Common.Packaging'),
  },
  [ProjectTypes.website]: {
    title: i18n.t('Common.Website'),
  },
  [ProjectTypes.calendar]: {
    title: i18n.t('Common.Calendar'),
  },
  [ProjectTypes.banner]: {
    title: i18n.t('Common.Banner'),
  },
  [ProjectTypes.facebookCover]: {
    title: i18n.t('Common.FacebookCover'),
  },
  [ProjectTypes.bookCover]: {
    title: i18n.t('Common.BookCover'),
  },
  [ProjectTypes.flier]: {
    title: i18n.t('Common.Flier'),
  },
  [ProjectTypes.carSticker]: {
    title: i18n.t('Common.CarSticker'),
  },
  [ProjectTypes.productLabel]: {
    title: i18n.t('Common.ProductLabel'),
  },
  [ProjectTypes.brochure]: {
    title: i18n.t('Common.Brochure'),
  },
  [ProjectTypes.illustration]: {
    title: i18n.t('Common.Illustration'),
  },
  [ProjectTypes.other]: {
    title: i18n.t('Common.Other'),
  },
}
