import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { beforeEach } from '@/router/beforeEach'
import Routes from './routes'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: Routes.DASHBOARD,
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/designers',
    name: Routes.DESIGNERS,
    component: () => import(/* webpackChunkName: "designers" */ '@/views/designers/DesignersList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients',
    name: Routes.CLIENTS,
    component: () => import(/* webpackChunkName: "clients" */ '@/views/clients/ClientsList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/projects',
    name: Routes.PROJECTS,
    component: () => import(/* webpackChunkName: "projects" */ '@/views/projects/ProjectsList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/projects/:id',
    name: Routes.PROJECTS_OVERVIEW,
    component: () => import(/* webpackChunkName: "projects" */ '@/views/projects/ProjectsOverview.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: Routes.LOGIN,
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(beforeEach)

export default router
