import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import TextField from '@/components/form/TextField.vue'
import VueCookies from 'vue-cookies'
import App from '@/App.vue'
import SelectField from '@/components/form/SelectField.vue'
import ToggleField from '@/components/form/ToggleField.vue'
import ActionButton from '@/components/ActionButton.vue'
import PageHeader from '@/components/PageHeader.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'

Vue.config.productionTip = false
Vue.use(VueCookies, { expire: '365d' })

Vue.component('TextField', TextField)
Vue.component('SelectField', SelectField)
Vue.component('ToggleField', ToggleField)
Vue.component('ActionButton', ActionButton)
Vue.component('PageHeader', PageHeader)

require('@/api/interceptors')

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
