
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ToggleField extends Vue {
  @Prop() value!: boolean
  @Prop() readonly placeholder!: string
  @Prop() errorMessages!: string | null
  @Prop() label!: string | null
  @Prop({ type: Boolean, default: false }) required!: boolean
  @Prop({ type: Boolean, default: true }) outlined!: boolean
  @Prop({ type: Boolean, default: false }) hideDetails!: boolean

  private model: boolean = this.value
}
