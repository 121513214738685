
import { Component, Prop, Vue } from 'vue-property-decorator'
import DialogContent from '@/components/dialog/DialogContent.vue'
import Client from '@/api/models/Client'
import SelectField from '@/components/form/SelectField.vue'
import ClientService from '@/api/services/ClientService'
import { ISnackbar, SnackbarIconType } from '@/api/interfaces/SnackbarInterface'
import { namespace } from 'vuex-class'

const Global = namespace('Global')

@Component({
  components: { SelectField, DialogContent },
})
export default class ClientUpdateDialog extends Vue {
  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Prop() private client!: Client
  @Prop() private onSuccess!: () => void
  @Prop() private updatedCallback!: () => void

  private isBlocked: boolean = !!this.client.blockedAt
  private faEmailEnabled: boolean = this.client.faEmailEnabled

  private submit(): void {
    ClientService.update(this.client.uuid, {
      is_blocked: this.isBlocked,
      fa_email_enabled: this.faEmailEnabled,
    }).then(() => {
      this.showSnackbar({
        type: SnackbarIconType.success,
        text: String(this.$t('Clients.UpdateDialog.UpdateSuccessSnack')),
      })

      this.onSuccess()
      this.closeModal()
    })
  }

  private closeModal(): void {
    this.$root.$emit('closeModal')
  }
}
