export interface IDesignerResponse {
  uuid: string
  first_name: string
  last_name: string
  email: string
  account_type: string
  phone: string
  address: string
  city: string
  country: string
  date_of_birth: string
  blocked_at: string
  fa_email_enabled: boolean
}

export default class Designer {
  uuid!: string
  firstName!: string
  lastName!: string
  email!: string
  accountType: string
  phone: string
  address: string
  city: string
  country: string
  dateOfBirth: string
  blockedAt: string
  faEmailEnabled: boolean

  constructor(data: IDesignerResponse) {
    this.uuid = data.uuid
    this.firstName = data.first_name
    this.lastName = data.last_name
    this.email = data.email
    this.accountType = data.account_type
    this.phone = data.phone
    this.address = data.address
    this.city = data.city
    this.country = data.country
    this.dateOfBirth = data.date_of_birth
    this.blockedAt = data.blocked_at
    this.faEmailEnabled = data.fa_email_enabled
  }
}
