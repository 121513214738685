import http from '@/api/http'
import { AxiosResponse } from 'axios'

export interface ILoginForm {
  email: string
  password: string
}
export default class AuthService {
  static login(credentials: ILoginForm): Promise<AxiosResponse> {
    return http.post('/admin/login', credentials)
  }
}
