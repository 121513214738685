
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ISnackbar, SnackbarIconType } from '@/api/interfaces/SnackbarInterface'

const Global = namespace('Global')

@Component
export default class Snackbar extends Vue {
  private snackbarModel: boolean = false
  private timeout: number = 3000

  @Global.Getter
  private snackbar!: ISnackbar

  @Global.Action
  private hideSnackbar!: () => void

  @Watch('snackbar') private onValueChange(): void {
    this.snackbarModel = true
  }

  get messageColor(): string {
    switch (this.snackbar.type) {
      case SnackbarIconType.success:
        return 'green'
      case SnackbarIconType.error:
        return 'red'
      case SnackbarIconType.warning:
        return 'orange'
      default:
        return 'white'
    }
  }
}
