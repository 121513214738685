
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SelectField extends Vue {
  @Prop() value!: string | null
  @Prop() errorMessages!: string | null
  @Prop() label!: string | null
  @Prop({ type: Boolean, default: false }) required!: boolean
  @Prop({ type: Boolean, default: false }) clearable!: boolean
  @Prop() items!: Array<object>
  @Prop() itemText!: string | null
  @Prop() itemValue!: string | null
  @Prop({ default: false, type: Boolean }) dense!: boolean
  @Prop({ default: false, type: Boolean }) hideDetails!: boolean
}
