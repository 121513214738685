export interface ISnackbar {
  type: SnackbarIconType
  title?: string
  text: string
}

export enum SnackbarIconType {
  success = 'mdi-check-circle-outline',
  info = 'mdi-information-outline',
  error = 'mdi-alert-circle',
  warning = 'mdi-alert',
}
