
import { Component, Vue } from 'vue-property-decorator'
import { ILanguageSwitcher, LanguageName } from '@/api/interfaces/LocaleInterface'
import { Locale } from 'vue-i18n'

@Component
export default class LocaleSwitcher extends Vue {
  private locales: Array<ILanguageSwitcher> = []
  private languageIcons: any = {
    lt: require('@/assets/images/languages/lt.svg'),
    en: require('@/assets/images/languages/en.svg'),
  }

  created(): void {
    this.locales = (this.$i18n.availableLocales as Locale[]).map((item: any) => {
      const index: number = Object.keys(LanguageName).indexOf(item)

      return { abbr: item, name: Object.values(LanguageName)[index] }
    })
  }

  private setLanguage(locale: string): void {
    this.$cookies.set('locale', locale)

    this.$nextTick(() => {
      window.location.reload()
    })
  }

  get selectableLocales(): ILanguageSwitcher[] {
    return this.locales.filter((locale) => locale.abbr !== this.$i18n.locale)
  }
}
