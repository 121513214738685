const authKey = 'languageToken'

const getToken = (): any => localStorage.getItem(authKey)

const setToken = (token: string): void => localStorage.setItem(authKey, token)

const clearToken = (): void => localStorage.removeItem(authKey)

const languageToken = {
  getToken,
  setToken,
  clearToken,
}

export default languageToken
