import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import VueCookies from 'vue-cookies'
import { loadLocaleMessages } from '@/api/helpers/loadLocaleMessages'

Vue.use(VueI18n)
Vue.use(VueCookies)

export default new VueI18n({
  locale: Vue.$cookies.get('locale') || 'lt',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'lt',
  messages: loadLocaleMessages() as LocaleMessages,
  silentTranslationWarn: process.env.NODE_ENV === 'test',
})
