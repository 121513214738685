export interface IUserResponse {
  uuid: string
  first_name: string
  last_name: string
  email: string
}

export default class User {
  uuid!: string
  firstName!: string
  lastName!: string
  email!: string

  constructor(data: IUserResponse) {
    this.uuid = data.uuid
    this.firstName = data.first_name
    this.lastName = data.last_name
    this.email = data.email
  }
}
