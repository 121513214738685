export function loadLocaleMessages(): object {
  if (process.env.NODE_ENV === 'test') {
    return { en: {} }
  }

  return {
    en: require('../../translations/en.json'),
    lt: require('../../translations/lt.json'),
  }
}
