
import { Component, Prop, Vue } from 'vue-property-decorator'
import DialogContent from '@/components/dialog/DialogContent.vue'
import SelectField from '@/components/form/SelectField.vue'
import { ISnackbar, SnackbarIconType } from '@/api/interfaces/SnackbarInterface'
import { namespace } from 'vuex-class'
import DesignerService from '@/api/services/DesignerService'
import Designer from '@/api/models/Designer'

const Global = namespace('Global')

@Component({
  components: { SelectField, DialogContent },
})
export default class DesignerUpdateDialog extends Vue {
  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Prop() private designer!: Designer
  @Prop() private onSuccess!: () => void
  @Prop() private updatedCallback!: () => void

  public isBlocked: boolean = !!this.designer.blockedAt
  public faEmailEnabled: boolean = this.designer.faEmailEnabled

  private submit(): void {
    DesignerService.update(this.designer.uuid, {
      is_blocked: this.isBlocked,
      fa_email_enabled: this.faEmailEnabled,
    }).then(() => {
      this.showSnackbar({
        type: SnackbarIconType.success,
        text: String(this.$t('Designers.UpdateDialog.UpdateSuccessSnack')),
      })

      this.onSuccess()
      this.closeModal()
    })
  }

  private closeModal(): void {
    this.$root.$emit('closeModal')
  }
}
