enum Routes {
  LOGIN = 'login',
  DASHBOARD = 'dashboard',
  DESIGNERS = 'designers',
  CLIENTS = 'clients',
  PROJECTS = 'projects',
  PROJECTS_OVERVIEW = 'projects/overview',
}

export default Routes
