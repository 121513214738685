import http from '@/api/http'
import { ICollectionResponse, IDataTableResponse } from '@/api/interfaces/DatatableInterface'
import Client, { IClientResponse } from '@/api/models/Client'
import { AxiosResponse } from 'axios'

export default class ClientService {
  static list(params: object | null): Promise<IDataTableResponse<ICollectionResponse<IClientResponse>>> {
    return http.get('admin/clients', { params })
  }

  static update(clientUuid: string, data: object): Promise<AxiosResponse> {
    return http.put(`admin/clients/${clientUuid}`, data)
  }

  static show(clientUuid: string): Promise<Client> {
    return http.get(`admin/clients/${clientUuid}`).then((response: any) => {
      return new Client(response.data)
    })
  }
}
