
import { Component, Prop, Vue } from 'vue-property-decorator'
import DialogContent from '@/components/dialog/DialogContent.vue'
import SelectField from '@/components/form/SelectField.vue'
import { ISnackbar, SnackbarIconType } from '@/api/interfaces/SnackbarInterface'
import { namespace } from 'vuex-class'
import ProjectService from '@/api/services/ProjectService'
import { offerStatuses } from '@/api/constants/OfferConstant'
import Project from '@/api/models/Project'
import { projectStatuses } from '@/api/constants/ProjectConstant'
import DateSelectField from '@/components/form/DateSelectField.vue'
import { getFieldError, getParsedValidationData, IErrors } from '@/api/helpers/validationErrorHandler'

const Global = namespace('Global')

@Component({
  components: { DateSelectField, SelectField, DialogContent },
})
export default class ProjectUpdateDialog extends Vue {
  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Prop() private project!: Project
  @Prop() private onSuccess!: () => void
  @Prop() private updatedCallback!: () => void

  public isSuspended: boolean = !!this.project.suspendedAt
  public offerStatus: string = this.project.selectedOffer?.statusUuid
  public projectStatus: string = this.project.statusUuid
  public startedAt: string | null = this.project.startedAt
  public endedAt: string | null = this.project.endedAt
  public offerStatuses: object[] = offerStatuses
  public projectStatuses: object[] = projectStatuses

  private errors: IErrors[] | null = null
  private loading: boolean = false

  private setStartDate(value: Date): void {
    if (!value) {
      this.startedAt = null

      return
    }

    this.startedAt = `${value}T00:00:00.000Z`
  }

  private setEndDate(value: Date): void {
    if (!value) {
      this.endedAt = null
    } else {
      this.endedAt = `${value}T00:00:00.000Z`
    }
  }

  private submit(): void {
    this.loading = true

    const data: any = {
      is_suspended: this.isSuspended,
      status_uuid: this.projectStatus,
      started_at: this.startedAt,
      ended_at: this.endedAt,
    }

    if (this.offerStatus) {
      data.offer_status_uuid = this.offerStatus
    }

    ProjectService.update(this.project.uuid, data)
      .then(() => {
        this.loading = false

        this.showSnackbar({
          type: SnackbarIconType.success,
          text: String(this.$t('Projects.UpdateDialog.UpdateSuccessSnack')),
        })

        this.onSuccess()
        this.closeModal()
      })
      .catch((error: any) => {
        this.loading = false

        if (error?.response?.data?.errors) {
          this.errors = getParsedValidationData(error?.response?.data?.errors)
        }
      })
  }

  private getErrorMessage(field: string): string | null {
    if (this.errors) {
      return getFieldError(field, this.errors)
    }

    return null
  }

  private closeModal(): void {
    this.$root.$emit('closeModal')
  }
}
