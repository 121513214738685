
import { Component, Vue, Watch } from 'vue-property-decorator'
import '@/assets/scss/app.scss'
import { namespace } from 'vuex-class'
import Login from '@/views/auth/Login.vue'
import LayoutContent from '@/components/layout/LayoutContent.vue'
import LayoutDrawer from '@/components/layout/LayoutDrawer.vue'
import LayoutBar from '@/components/layout/LayoutBar.vue'
import ConfirmDialog from '@/components/dialog/ConfirmDialog.vue'
import Snackbar from '@/components/Snackbar.vue'
import ModalDialog from '@/components/dialog/ModalDialog.vue'
import { AuthActions } from '@/store/actions/auth.actions'
import tokenHelper from '@/api/helpers/tokenHelper'
import Routes from './router/routes'

const Auth = namespace('Auth')

@Component({
  components: { LayoutContent, LayoutDrawer, LayoutBar, Login, ConfirmDialog, Snackbar, ModalDialog },
})
export default class App extends Vue {
  @Auth.Getter
  private isLoggedIn!: boolean

  @Auth.Getter
  private getUser!: boolean

  @Watch('isLoggedIn') sessionStateChanged(isLoggedIn: boolean): void {
    if (!isLoggedIn && this.$route.name !== Routes.LOGIN) {
      this.$router.push({ name: Routes.LOGIN })
    }
  }

  created(): void {
    if (tokenHelper.getToken() && !this.getUser) {
      this.$store.dispatch(AuthActions.GET_ME)
    }
  }
}
