import http from '@/api/http'
import { AxiosResponse } from 'axios'
import Client from '@/api/models/Client'
import Project from '@/api/models/Project'

export default class ProjectService {
  static list(params: object | null): Promise<AxiosResponse> {
    return http.get('admin/projects', { params })
  }

  static update(projectUuid: string, data: object): Promise<AxiosResponse> {
    return http.put(`admin/projects/${projectUuid}`, data)
  }

  static show(projectUuid: string): Promise<Project> {
    return http.get(`admin/projects/${projectUuid}`).then((response: any) => {
      return new Project(response.data)
    })
  }
}
