import i18n from '@/plugins/i18n'

export const offerStatuses = [
  {
    value: 'offers.canceled',
    label: i18n.t('Common.Canceled'),
  },
  {
    value: 'offers.completed',
    label: i18n.t('Common.Completed'),
  },
  {
    value: 'offers.declined',
    label: i18n.t('Common.Declined'),
  },
  {
    value: 'offers.draft',
    label: i18n.t('Common.Draft'),
  },
  {
    value: 'offers.left',
    label: i18n.t('Common.Left'),
  },
  {
    value: 'offers.offered',
    label: i18n.t('Common.Offered'),
  },
  {
    value: 'offers.pending',
    label: i18n.t('Common.Pending'),
  },
  {
    value: 'offers.selected',
    label: i18n.t('Common.Selected'),
  },
  {
    value: 'offers.sent',
    label: i18n.t('Common.Sent'),
  },
]
