
import { Component, Vue } from 'vue-property-decorator'
import DataTable from '@/components/datatable/DataTable.vue'

@Component({
  components: { DataTable },
  data: () => ({
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: 'round',
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    gradientDirection: 'top',
    fill: false,
    type: 'trend',
    autoLineWidth: false,
  }),
})
export default class Dashboard extends Vue {}
