var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"rounded":"sm"}},[_c('v-card-actions',[_c('v-fade-transition',[(_vm.hasItemsSelected)?_c('v-row',{attrs:{"no-gutters":""}},[_vm._t("bulk-actions")],2):_vm._e()],1)],1),_c('v-card-title',[_c('v-row',{attrs:{"justify":"end"}},[_vm._t("pre-search-actions"),_c('v-col',[_c('v-text-field',{attrs:{"disabled":_vm.isOrderingEnabled,"label":_vm.$t('DataTable.SearchLabel'),"append-icon":"mdi-magnify","dense":"","hide-details":"","outlined":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_vm._t("actions")],2)],1),_c('v-data-table',_vm._b({ref:"dataTable",staticClass:"elevation-1",attrs:{"footer-props":{
      'items-per-page-options': [25, 50, 100],
      'items-per-page-text': _vm.$t('DataTable.ItemsPerPage'),
      'show-current-page': true,
      'show-first-last-page': true,
      pagination: this.paginationData,
    },"headers":_vm.headers,"hide-default-footer":_vm.isOrderingEnabled,"item-class":(item) => (_vm.isOrderingEnabled ? `sortable-row sort-handle id-${item.id}` : ''),"item-key":_vm.itemKey,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"loading":_vm.isLoading,"loading-text":_vm.$t('DataTable.LoadingText'),"no-data-text":_vm.$t('DataTable.NoDataText'),"options":_vm.tableOptions,"server-items-length":_vm.paginationData.itemsLength,"show-select":_vm.hasBulkActions,"disable-pagination":_vm.isOrderingEnabled,"disable-sort":_vm.isOrderingEnabled,"sort-desc":_vm.sortDesc},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.fetch],"item-selected":_vm.itemsSelected,"toggle-select-all":_vm.itemsSelected},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),_vm._l((_vm.defaultHeaders),function(header){return {key:`item.${header.value}`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.resolveItemValue(item, header.value))+" ")]}}}),{key:"item.sortHandle",fn:function(){return [_c('v-icon',[_vm._v("mdi-drag-horizontal-variant")])]},proxy:true},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$t('DataTable.PageText', { start: props.pageStart, end: props.pageStop, of: props.itemsLength }))+" ")]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }