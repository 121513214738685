
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import i18n from '@/plugins/i18n'
import Routes from '@/router/routes'
import { IMenuItem } from '@/api/interfaces/LayoutDrawerInterface'

const Global = namespace('Global')

@Component
export default class LayoutDrawer extends Vue {
  private drawer = true

  private items: IMenuItem[] = [
    {
      text: i18n.t('Menu.Dashboard'),
      icon: 'mdi-widgets-outline',
      route: Routes.DASHBOARD,
      exact: true,
    },
    {
      text: i18n.t('Menu.Management'),
    },
    {
      text: i18n.t('Menu.Designers'),
      icon: 'mdi-brush-outline',
      route: Routes.DESIGNERS,
    },
    {
      text: i18n.t('Menu.Clients'),
      icon: 'mdi-account-check-outline',
      route: Routes.CLIENTS,
    },
    {
      text: i18n.t('Menu.Projects'),
      icon: 'mdi-animation-play-outline',
      route: Routes.PROJECTS,
    },
  ]

  @Global.Getter
  private appVersion!: string

  created(): void {
    this.$root.$on('toggleDrawer', this.toggleDrawer)
  }

  beforeDestroy(): void {
    this.$root.$off('toggleDrawer', this.toggleDrawer)
  }

  private toggleDrawer(): void {
    this.drawer = !this.drawer
  }

  private routeTo(route: string): void {
    if (route !== this.$router.currentRoute.name) this.$router.push({ name: route })
  }
}
