import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { globalMutations } from '@/store/mutations/global.mutations'
import IModalDialog from '@/api/interfaces/ModalDialogInterface'
import { ISnackbar } from '@/api/interfaces/SnackbarInterface'
import IConfirmDialog from '@/api/interfaces/ConfirmDialogInterface'

@Module({ namespaced: true })
class Global extends VuexModule {
  public packageVersion = process.env.VUE_APP_VERSION
  public confirmDialogInstance: IConfirmDialog | null = null
  public modalDialogInstance: IModalDialog | null = null
  public snackbarInstance: ISnackbar | null = null
  public loading: boolean = false

  @Mutation
  setSnackbar(snackbar: ISnackbar | null): void {
    this.snackbarInstance = snackbar
  }

  @Mutation
  setConfirmDialog(confirmDialog: IConfirmDialog | null): void {
    this.confirmDialogInstance = confirmDialog
  }

  @Mutation
  setModalDialog(modalDialog: IModalDialog | null): void {
    this.modalDialogInstance = modalDialog
  }

  @Mutation
  setLoading(loadingState: boolean): void {
    this.loading = loadingState
  }

  @Action
  hideConfirmDialog(): void {
    this.context.commit(globalMutations.SET_CONFIRM_DIALOG, null)
  }

  @Action
  showConfirmDialog(confirmDialog: IConfirmDialog): void {
    this.context.commit(globalMutations.SET_CONFIRM_DIALOG, confirmDialog)
  }

  @Action
  showSnackbar(snackbar: ISnackbar): void {
    this.context.commit(globalMutations.SET_SNACKBAR, snackbar)
  }

  @Action
  hideSnackbar(): void {
    this.context.commit(globalMutations.SET_SNACKBAR, null)
  }

  @Action
  hideModalDialog(): void {
    this.context.commit(globalMutations.SET_MODAL_DIALOG, null)
  }

  @Action
  showModalDialog(modalDialog: IModalDialog): void {
    this.context.commit(globalMutations.SET_MODAL_DIALOG, modalDialog)
  }

  @Action
  enableLoader(): void {
    this.context.commit(globalMutations.SET_LOADING, true)
  }

  @Action
  disableLoader(): void {
    this.context.commit(globalMutations.SET_LOADING, false)
  }

  get snackbar(): ISnackbar | null {
    return this.snackbarInstance
  }

  get confirmDialog(): IConfirmDialog | null {
    return this.confirmDialogInstance
  }

  get modalDialog(): IModalDialog | null {
    return this.modalDialogInstance
  }

  get appVersion(): string {
    return this.packageVersion || ''
  }

  get isLoading(): boolean {
    return this.loading
  }
}

export default Global
