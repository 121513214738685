
import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTable from '@/components/datatable/DataTable.vue'
import { ICollectionResponse, IDataTableHeader, IDataTableResponse } from '@/api/interfaces/DatatableInterface'
import ClientService from '@/api/services/ClientService'
import Client, { IClientResponse } from '@/api/models/Client'
import { namespace } from 'vuex-class'
import ClientUpdateDialog from '@/views/clients/ClientUpdateDialog.vue'
import IModalDialog from '@/api/interfaces/ModalDialogInterface'

const Global = namespace('Global')

@Component({
  components: { DataTable },
})
export default class UserList extends Vue {
  @Prop() role!: number | null

  private headers: IDataTableHeader[] = [
    {
      text: String(this.$t('Clients.List.FirstName')),
      value: 'firstName',
    },
    {
      text: String(this.$t('Clients.List.LastName')),
      value: 'lastName',
    },
    {
      text: String(this.$t('Clients.List.AccountType')),
      value: 'accountType',
      sortable: false,
    },
    {
      text: String(this.$t('Clients.List.Email')),
      value: 'email',
      sortable: false,
    },
    {
      text: String(this.$t('Clients.List.Phone')),
      value: 'phone',
      sortable: false,
    },
    {
      text: String(this.$t('Clients.List.Address')),
      value: 'address',
      sortable: false,
    },
    {
      text: String(this.$t('Clients.List.City')),
      value: 'city',
      sortable: false,
    },
    {
      text: String(this.$t('Clients.List.Country')),
      value: 'country',
      sortable: false,
    },
    {
      text: String(this.$t('Clients.List.DateOfBirth')),
      value: 'dateOfBirth',
      sortable: false,
    },
    {
      text: String(this.$t('Clients.List.ProjectsCount')),
      value: 'projectsCount',
      sortable: false,
    },
    {
      text: String(this.$t('Clients.List.ActiveProjectsCount')),
      value: 'activeProjectsCount',
      sortable: false,
    },
    {
      text: String(this.$t('Clients.List.Status')),
      value: 'blockedAt',
      sortable: false,
    },
    {
      text: String(this.$t('Clients.List.TFAEnabled')),
      value: 'faEmailEnabled',
      sortable: false,
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
    },
  ]

  @Global.Action
  private showModalDialog!: (modalDialog: IModalDialog) => void

  public fetchCallback(parameters: object): Promise<IDataTableResponse<ICollectionResponse<Client>>> {
    return ClientService.list(parameters).then((response: IDataTableResponse<ICollectionResponse<IClientResponse>>) => {
      return {
        ...response,
        data: {
          data: response.data.data.map((item: IClientResponse) => new Client(item)),
          meta: response.data.meta,
        },
      }
    })
  }

  public editItem(client: Client): void {
    this.showModalDialog({
      component: ClientUpdateDialog,
      maxWidth: 800,
      propsData: {
        client,
        onSuccess: this.dataTableRefresh,
      },
    })
  }

  private dataTableRefresh(): void {
    const dataTable = this.$refs.dataTable as DataTable
    dataTable.fetch()
  }
}
