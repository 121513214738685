
import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTable from '@/components/datatable/DataTable.vue'
import { ICollectionResponse, IDataTableHeader, IDataTableResponse } from '@/api/interfaces/DatatableInterface'
import Designer, { IDesignerResponse } from '@/api/models/Designer'
import DesignerService from '@/api/services/DesignerService'
import IModalDialog from '@/api/interfaces/ModalDialogInterface'
import { namespace } from 'vuex-class'
import DesignerUpdateDialog from '@/views/designers/DesignerUpdateDialog.vue'

const Global = namespace('Global')

@Component({
  components: { DataTable },
})
export default class DesignersList extends Vue {
  @Prop() role!: number | null

  private headers: IDataTableHeader[] = [
    {
      text: String(this.$t('Designers.List.FirstName')),
      value: 'firstName',
    },
    {
      text: String(this.$t('Designers.List.LastName')),
      value: 'lastName',
    },
    {
      text: String(this.$t('Designers.List.AccountType')),
      value: 'accountType',
      sortable: false,
    },
    {
      text: String(this.$t('Designers.List.Email')),
      value: 'email',
      sortable: false,
    },
    {
      text: String(this.$t('Designers.List.Phone')),
      value: 'phone',
      sortable: false,
    },
    {
      text: String(this.$t('Designers.List.Address')),
      value: 'address',
      sortable: false,
    },
    {
      text: String(this.$t('Designers.List.City')),
      value: 'city',
      sortable: false,
    },
    {
      text: String(this.$t('Designers.List.Country')),
      value: 'country',
      sortable: false,
    },
    {
      text: String(this.$t('Designers.List.DateOfBirth')),
      value: 'dateOfBirth',
      sortable: false,
    },
    {
      text: String(this.$t('Designers.List.Status')),
      value: 'blockedAt',
      sortable: false,
    },
    {
      text: String(this.$t('Designers.List.TFAEnabled')),
      value: 'faEmailEnabled',
      sortable: false,
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
    },
  ]

  @Global.Action
  private showModalDialog!: (modalDialog: IModalDialog) => void

  public fetchCallback(parameters: object): Promise<IDataTableResponse<ICollectionResponse<Designer>>> {
    return DesignerService.list(parameters).then(
      (response: IDataTableResponse<ICollectionResponse<IDesignerResponse>>) => {
        return {
          ...response,
          data: {
            data: response.data.data.map((item: IDesignerResponse) => new Designer(item)),
            meta: response.data.meta,
          },
        }
      },
    )
  }

  public editItem(designer: Designer): void {
    this.showModalDialog({
      component: DesignerUpdateDialog,
      maxWidth: 800,
      propsData: {
        designer,
        onSuccess: this.dataTableRefresh,
      },
    })
  }

  private dataTableRefresh(): void {
    const dataTable = this.$refs.dataTable as DataTable
    dataTable.fetch()
  }
}
