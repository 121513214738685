
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import IConfirmDialog from '@/api/interfaces/ConfirmDialogInterface'

const Global = namespace('Global')

@Component
export default class ConfirmDialog extends Vue {
  private loading: boolean = false
  private dialog: boolean = false

  @Global.Getter
  private confirmDialog!: IConfirmDialog

  @Global.Action
  private hideConfirmDialog!: () => void

  @Watch('confirmDialog') private onValueChange(): void {
    this.dialog = true
  }

  async onConfirm(): Promise<any> {
    this.loading = true

    if (this.confirmDialog.confirmAction) {
      await this.confirmDialog.confirmAction()
      this.loading = false
    }

    this.hideConfirmDialog()
  }

  onCancel(): void {
    this.hideConfirmDialog()
  }
}
