import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import http from '@/api/http'
import tokenHelper from '@/api/helpers/tokenHelper'
import languageToken from '@/api/helpers/languageHelper'
import i18n from '@/plugins/i18n'
import store from '@/store'
import { AuthMutations } from '@/store/mutations/auth.mutations'
import router from '@/router/index'
import Routes from '@/router/routes'

http.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = tokenHelper.getToken()

  const langToken = languageToken.getToken()

  if (token) config.headers.Authorization = `Bearer ${token}`

  config.headers['Content-Type'] = 'application/json'

  if (langToken) {
    config.headers['X-localization'] = langToken

    return config
  }

  config.headers['X-localization'] = i18n.locale

  return config
})

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError<any>) => {
    if (error.response?.status === 401) {
      store.commit(AuthMutations.reset)

      router.push({ name: Routes.LOGIN })
    }
    throw error
  },
)
