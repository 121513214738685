
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class DateSelectField extends Vue {
  @Prop() errorMessages!: string | null
  @Prop() label!: string | null
  @Prop() value!: string | null
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) required!: boolean
  public date = this.value
  private menu = false

  @Watch('date') private onValueChange(): void {
    this.$emit('update', this.date)
  }
}
