export enum Locale {
  en = 'en',
  lt = 'lt',
}

export enum LanguageName {
  en = 'English',
  lt = 'Lietuvių',
}

export interface ILanguageSwitcher {
  name: LanguageName
  abbr: Locale
}

export interface ILanguageOptions {
  label: LanguageName
  key: Locale
}
