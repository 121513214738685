import { NavigationGuardNext, RawLocation } from 'vue-router'
import AdminRoutes from '@/router/routes'
import tokenHelper from '@/api/helpers/tokenHelper'

export const beforeEach = (
  to: RawLocation | any,
  from: RawLocation | any,
  next: NavigationGuardNext | any,
): Promise<void> => {
  if (to.meta.requiresAuth && !tokenHelper.getToken() && to.name !== AdminRoutes.LOGIN) {
    return next(AdminRoutes.LOGIN)
  }

  if (!to.meta.requiresAuth && tokenHelper.getToken() && to.name === AdminRoutes.LOGIN) {
    return next({ name: AdminRoutes.DASHBOARD })
  }

  return next()
}
