
import { Component, Vue } from 'vue-property-decorator'
import DataTable from '@/components/datatable/DataTable.vue'
import IModalDialog from '@/api/interfaces/ModalDialogInterface'
import { namespace } from 'vuex-class'
import ProjectService from '@/api/services/ProjectService'
import Project from '@/api/models/Project'
import OverviewItem from '@/components/OverviewItem.vue'
import ProjectUpdateDialog from '@/views/projects/ProjectUpdateDialog.vue'

const Global = namespace('Global')

@Component<ProjectsOverview>({
  components: { OverviewItem, DataTable },
  async beforeRouteEnter(to, from, next) {
    const project = await ProjectService.show(to.params.id)
    next((vm: ProjectsOverview) => {
      vm.project = project
    })
  },
})
export default class ProjectsOverview extends Vue {
  @Global.Action
  private showModalDialog!: (modalDialog: IModalDialog) => void

  private project: Project | null = null

  public openEditDialog(): void {
    this.showModalDialog({
      component: ProjectUpdateDialog,
      maxWidth: 800,
      propsData: {
        project: this.project,
        onSuccess: this.refetchProject,
      },
    })
  }

  private async refetchProject(): Promise<void> {
    this.project = await ProjectService.show(this.$route.params.id)
  }
}
