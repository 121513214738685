import http from '@/api/http'
import { AxiosResponse } from 'axios'
import Designer from '@/api/models/Designer'

export default class DesignerService {
  static list(params: object | null): Promise<AxiosResponse> {
    return http.get('admin/designers', { params })
  }

  static update(designerUuid: string, data: object): Promise<AxiosResponse> {
    return http.put(`admin/designers/${designerUuid}`, data)
  }

  static show(designerUuid: string): Promise<Designer> {
    return http.get(`admin/designers/${designerUuid}`).then((response: any) => {
      return new Designer(response.data)
    })
  }
}
