import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import User, { IUserResponse } from '@/api/models/User'
import http from '@/api/http'
import ResponseInterface from '@/api/interfaces/ResponseInterface'
import { AuthMutations } from '@/store/mutations/auth.mutations'
import tokenHelper from '@/api/helpers/tokenHelper'

@Module({ namespaced: true })
class Auth extends VuexModule {
  user: User | null = null
  get isLoggedIn(): boolean {
    return !!this.user
  }
  get getUser(): User | null {
    return this.user
  }

  @Mutation
  public setUser(user: User | null): void {
    this.user = user
  }

  @Mutation
  public reset(): void {
    this.user = null
  }

  @Action
  public getMe(): void {
    http
      .get('/admin/me')
      .then((response: ResponseInterface<IUserResponse>) => {
        this.context.commit(AuthMutations.setUser, new User(response.data))
      })
      .catch(() => {
        tokenHelper.clearToken()
        this.context.commit(AuthMutations.reset)
      })
  }

  @Action
  public logOut(): void {
    if (!this.user) {
      return
    }

    http
      .post('/admin/logout')
      .then(() => {
        this.context.commit(AuthMutations.reset)
        localStorage.clear()
      })
      .catch(() => {
        tokenHelper.clearToken()
        this.context.commit(AuthMutations.reset)
      })
  }
}

export default Auth
