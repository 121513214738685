
import { Component, Vue } from 'vue-property-decorator'
import DataTable from '@/components/datatable/DataTable.vue'
import { ICollectionResponse, IDataTableHeader, IDataTableResponse } from '@/api/interfaces/DatatableInterface'
import IModalDialog from '@/api/interfaces/ModalDialogInterface'
import { namespace } from 'vuex-class'
import ProjectService from '@/api/services/ProjectService'
import Project, { IProjectResponse } from '@/api/models/Project'
import ClientOverviewDialog from '@/views/clients/ClientOverviewDialog.vue'
import DesignerOverviewDialog from '@/views/designers/DesignerOverviewDialog.vue'
import ProjectUpdateDialog from '@/views/projects/ProjectUpdateDialog.vue'
import Routes from '@/router/routes'

const Global = namespace('Global')

@Component({
  components: { DataTable },
})
export default class ProjectsList extends Vue {
  private headers: IDataTableHeader[] = [
    {
      text: String(this.$t('Projects.List.TitleColumn')),
      value: 'name',
    },
    {
      text: String(this.$t('Projects.List.Type')),
      value: 'type',
    },
    {
      text: String(this.$t('Projects.List.Owner')),
      value: 'owner.fullName',
      sortable: false,
    },
    {
      text: String(this.$t('Projects.List.OffersCount')),
      value: 'offersCount',
      sortable: false,
    },
    {
      text: String(this.$t('Projects.List.Designer')),
      value: 'selectedOffer.user.fullName',
      sortable: false,
    },
    {
      text: String(this.$t('Projects.List.State')),
      value: 'status',
      sortable: false,
    },
    {
      text: String(this.$t('Projects.List.StartedAt')),
      value: 'startedAt',
      sortable: false,
    },
    {
      text: String(this.$t('Projects.List.EndedAt')),
      value: 'endedAt',
      sortable: false,
    },
    {
      text: String(this.$t('Projects.List.SelectedOffer')),
      value: 'selectedOffer.uuid',
      sortable: false,
    },
    {
      text: String(this.$t('Projects.List.SelectedOfferStatus')),
      value: 'selectedOffer.status',
      sortable: false,
    },
    {
      text: String(this.$t('Projects.List.Status')),
      value: 'suspendedAt',
      sortable: false,
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
    },
  ]

  @Global.Action
  private showModalDialog!: (modalDialog: IModalDialog) => void

  public fetchCallback(parameters: object): Promise<IDataTableResponse<ICollectionResponse<Project>>> {
    return ProjectService.list(parameters).then(
      (response: IDataTableResponse<ICollectionResponse<IProjectResponse>>) => {
        return {
          ...response,
          data: {
            data: response.data.data.map((item: IProjectResponse) => new Project(item)),
            meta: response.data.meta,
          },
        }
      },
    )
  }

  private openOwnerOverview(clientUuid: string): void {
    this.showModalDialog({
      component: ClientOverviewDialog,
      maxWidth: 800,
      propsData: {
        clientUuid,
      },
    })
  }

  private openDesignerOverview(designerUuid: string): void {
    if (!designerUuid) {
      return
    }

    this.showModalDialog({
      component: DesignerOverviewDialog,
      maxWidth: 800,
      propsData: {
        designerUuid,
      },
    })
  }

  public editItem(project: Project): void {
    this.showModalDialog({
      component: ProjectUpdateDialog,
      maxWidth: 800,
      propsData: {
        project,
        onSuccess: this.dataTableRefresh,
      },
    })
  }

  public openOverview(project: Project): void {
    this.$router.push({ name: Routes.PROJECTS_OVERVIEW, params: { id: project.uuid } })
  }

  private dataTableRefresh(): void {
    const dataTable = this.$refs.dataTable as DataTable
    dataTable.fetch()
  }
}
