
import { Component, Vue } from 'vue-property-decorator'
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import LayoutFooter from '@/components/layout/LayoutFooter.vue'
import { namespace } from 'vuex-class'
import LayoutBasicBar from '@/components/layout/LayoutBasicBar.vue'
import Routes from '@/router/routes'
import { ISnackbar } from '@/api/interfaces/SnackbarInterface'
import AuthService, { ILoginForm } from '@/api/services/AuthService'
import { AxiosResponse } from 'axios'
import tokenHelper from '@/api/helpers/tokenHelper'
import { AuthActions } from '@/store/actions/auth.actions'
import { getFieldError, getParsedValidationData, IErrors } from '@/api/helpers/validationErrorHandler'

const Global = namespace('Global')

@Component({
  components: { LocaleSwitcher, LayoutFooter, LayoutBasicBar },
})
export default class Login extends Vue {
  private loginForm: ILoginForm = { email: '', password: '' }
  private message: string = ''
  private loading: boolean = false
  private errors: IErrors[] | null = null

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  submit(): void {
    this.message = ''
    this.loading = true

    AuthService.login(this.loginForm)
      .then((response: AxiosResponse) => {
        this.loading = false

        this.onSuccess(response.data)
      })
      .catch((error: any) => {
        this.loading = false

        if (error?.response?.data?.errors) {
          this.errors = getParsedValidationData(error?.response?.data?.errors)
        }
      })
  }

  async onSuccess(response: any): Promise<void> {
    tokenHelper.setToken(response.access_token)
    await this.$store.dispatch(AuthActions.GET_ME)

    this.$router.push({
      name: Routes.DASHBOARD,
    })
  }

  private getErrorMessage(field: string): string | null {
    if (this.errors) {
      return getFieldError(field, this.errors)
    }

    return null
  }
}
