import { find, get } from 'lodash'
import { projectStatuses, projectTypes } from '@/api/constants/ProjectConstant'
import { offerStatuses } from '@/api/constants/OfferConstant'

export interface IProjectResponse {
  uuid: string
  name: string
  type: string
  owner: { uuid: string; full_name: string }
  offers_count: number
  selected_offer: {
    uuid: string
    status_uuid: string
    status: string
    user: {
      uuid: string
      full_name: string
    } | null
  }
  started_at: string
  ended_at: string
  status_uuid: string
  status: string
  suspended_at: string
}

export default class Project {
  uuid: string
  name: string
  type: string
  owner: { uuid: string; fullName: string }
  offersCount: number
  selectedOffer: {
    uuid: string
    statusUuid: string
    status: string
    user: {
      uuid: string
      fullName: string
    } | null
  }
  startedAt: string
  endedAt: string
  statusUuid: string
  status: string
  suspendedAt: string

  constructor(data: IProjectResponse) {
    this.uuid = data.uuid
    this.name = data.name
    this.type = data.type
    this.owner = data.owner && {
      uuid: data.owner.uuid,
      fullName: data.owner.full_name,
    }
    this.offersCount = data.offers_count
    this.selectedOffer = data.selected_offer && {
      uuid: data.selected_offer.uuid,
      statusUuid: data.selected_offer.status_uuid,
      status: data.selected_offer.status,
      user: data.selected_offer.user && {
        uuid: data.selected_offer.user.uuid,
        fullName: data.selected_offer.user.full_name,
      },
    }
    this.startedAt = data.started_at
    this.endedAt = data.ended_at
    this.statusUuid = data.status_uuid
    this.status = data.status
    this.suspendedAt = data.suspended_at
  }

  get translatedStatus(): string {
    const result = find(projectStatuses, (status: any) => status.value === this.statusUuid)?.label

    return (result || '-') as string
  }

  get projectType(): any {
    if (!this.type) {
      return null
    }

    return get(projectTypes, this.type)
  }

  get offerStatus(): any {
    return find(offerStatuses, (status: any) => status.value === this.selectedOffer?.statusUuid)?.label
  }
}
