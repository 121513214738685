import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@/store/modules/auth.module'
import Global from '@/store/modules/global.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Auth,
    Global,
  },
})
